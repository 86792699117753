<template>
  <div class="u__background--white">
    <Content>
      <FlexContainer padding="normal">
        <FlexItem padding="normal" :grow="true">
          <p>
            We're sorry but your account has not been verified. Please contact
            us and we will enable your account.
          </p>
          <p v-if="false">
            If you think something is broken then please don't hesitate to
            <router-link to="/contact"> contact us </router-link>.
          </p>
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isVerified: 'user/isVerified'
    })
  },
  mounted() {
    if (this.isVerified) {
      this.$router.push('/')
    }
  }
}
</script>
